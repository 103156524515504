module.exports = {
	"order": {
		"mass_update": {
			"action": "Действие",
			"reason": "Причина",
			"comment": "Комментарий",
			"left": "Осталось",
			"failed": "Не удалось обработать"
		},
		"action": {
			"blank": "Комментировать",
			"payout": "Оплатить сейчас",
			"payoutAtTime": "Оплатить в заданное время",
			"reject": "Отклонить",
			"repay": "Перезапросить выплату",
			"refund": "Запросить рефанд",
			"declareRefund": "Зафиксировать сделанный рефанд",
			"redeposit": "Создать повторный депозит",
			"decreasePayout": "Уменьшить сумму выплаты",
			"action_success": "Действие успешно выполнено",
			"banCard": "Заблокировать карту",
			"unbanCard": "Разблокировать карту",
			"cancel": "Отменить в процессинге"
		},
		"agree": {
			"repay": "Я точно уверен, что выплата не ушла в процессинг",
			"refund": "Я точно уверен, что хочу сделать рефанд",
			"redeposit": "Я точно уверен, что хочу создать повторный депозит",
			"decreasePayout": "Я точно уверен, что пользователь получил выплату меньшего размера, чем заявка"
		},
		"rejectAll": "Отменить все заявки игрока",
		"title": "Заявки",
		"order": "Заявка",
		"download": "Скачать",
		"information": {
			"title": "Информация",
			"order_details": {
				"title": "Детали заявки",
				"id": "ID заявки",
				"project": "Проект",
				"jurisdiction": "Юрисдикция",
				"customer_country": "Страна игрока",
				"type": "Тип",
				"amount": "Сумма",
				"billing_amount": "Сумма платежа",
				"refundable_amount": "Сумма, доступная для рефанда",
				"refunds_amount": "Сумма рефандов",
				"billing_type": "Тип платежа",
				"processor": "Процессинг",
				"processor_channel": "Канал процессинга",
				"system": "Система",
				"bank": "Банк",
				"purse": "Кошелек",
				"processor_fee": "Комиссия процессинга (расчетная)",
				"processor_fee_external": "Комиссия процессинга (внешняя)",
				"project_fee": "Комиссия проекта",
				"customer_fee": "Наша комиссия",
				"personal_fee": "Персональная комиссия",
				"external_fee": "Комиссия сети криптовалюты",
				"status": "Статус",
				"error_code": "Код отказа",
				"error_type": "Тип отказа",
				"support_status": "Статус суппорта",
				"rejection_reason": "Причина отклонения",
				"status_updated_at": "Статус обновлен",
				"transaction_id": "ID транзакции",
				"secondary_txids": "Вторичные ID",
				"created_at": "Заявка создана",
				"updated_at": "Заявка обновлена",
				"scheduled_at": "Выплата запланирована",
				"refcode": "Реферальный код",
				"processor_account": "Аккаунт процессинга",
				"processed_at": "Дата обработки процессингом",
				"three_ds_status": "3DS статус",
				"deposit_number": "Номер депозита",
				"purse_country": "Страна кошелька"
			},
			"three_ds_status": {
				"passed": "3DS пройден",
				"failed": "3DS провален или не предпринят",
				"attempted": "3DS был предпринят, но не был проведен",
				"not_initiated": "3DS не проводился",
				"no_data": "Нет данных"
			},
			"processor_details": {
				"title": "Информация от процессинга"
			},
			"purse_details": {
				"title": "Информация по кошельку"
			},
			"token_details": {
				"title": "Детали сессии",
				"guid": "GUID",
				"locale": "Локаль",
				"country": "Страна по IP",
				"mode": "Режим кассы",
				"mobile": "Моб. версия",
				"device": "Устройство",
				"browser": "Браузер",
				"browser_engine": "Движок",
				"browser_is_webview": "Webview",
				"os": "ОС",
				"widget_origin": "URL открытия",
				"vwo_variations": "Вариации VWO",
				"tracking_id": "ID отслеживания",
				"platform": "Платформа",
				"source": "Источник",
				"open_time": "Время открытия кассы"
			},
			"order_comments": {
				"title": "Комментарии"
			},
			"customer_comments": {
				"title": "Комментарии по игроку"
			},
			"customer_details": {
				"title": "Игрок"
			},
			"bin": {
				"title": "Данные BIN",
				"brand": "Бренд",
				"issuer": "Банк-эмитент",
				"type": "Тип",
				"level": "Уровень",
				"prepaid": "Предоплаченная",
				"business": "Бизнес",
				"country": "Страна",
				"website": "Сайт банка",
				"phone": "Телефон",
				"add_info": "Доп. инфо"
			}
		},
		"action_logs": {
			"title": "Лог действий",
			"user": "Пользователь",
			"type": "Тип",
			"action": "Действие",
			"date": "Дата"
		},
		"tech_logs": {
			"title": "Технические логи",
			"types": {
				"project_request": "Запросы в проекты",
				"processor_request": "Запросы в процессинг",
				"callback": "Коллбеки",
				"return": "Возвраты из формы оплаты",
				"widget_response": "Ответы виджету"
			}
		},
		"status_history": {
			"title": "История статусов",
			"old_status": "Старый статус",
			"new_status": "Новый статус",
			"change_interval": "Время на изменение статуса",
			"user": "Пользователь",
			"changed_at": "Дата изменения статуса",
			"order_created_at": "Дата создания заявки"
		},
		"operations": {
			"title": "Операции",
			"id": "ID операции",
			"guid": "GUID операции",
			"txid": "ID транзакции",
			"status": "Статус",
			"createdAt": "Дата создания",
			"updatedAt": "Дата обновления",
			"amount": "Сумма операции",
			"type": "Тип",
			"types": {
				"deposit": "Депозит",
				"withdraw": "Выплата",
				"refund": "Рефанд",
				"cancel": "Отмена"
			},
			"statusUpdatedAt": "Дата обновления статуса"
		},
		"external_operations": {
			"title": "Внешние операции",
			"operation_id": "ID операции",
			"id": "ID",
			"guid": "GUID",
			"type": "Тип",
			"types": {
				"external_3ds": "Внешний 3DS",
				"authorization": "Авторизация",
				"payout": "Выплата",
				"void": "Void",
				"capture": "Capture",
				"refund": "Рефанд"
			},
			"cascade_number": "Номер круга каскадинга",
			"attempt_number": "Номер попытки каскадинга",
			"status": "Статус",
			"amount": "Сумма",
			"provider": "Провайдер",
			"provider_amount": "Сумма платежа",
			"provider_fee": "Комиссия провайдера",
			"provider_reference": "ID операции провайдера",
			"decline_info": "Инфо об отказе",
			"decline_info_fields": {
				"reason": "Причина",
				"type": "Тип",
				"code": "Код",
				"message": "Сообщение"
			},
			"created_at": "Дата создания",
			"status_updated_at": "Дата обновления статуса"
		},
		"billing_types": {
			"normal": "Обычный",
			"free": "Бесплатный",
			"test": "Тестовый",
			"internal": "Внутренний"
		},
		"player": "Игрок",
		"type": "Тип",
		"processor": "Процессинг",
		"payment_system": "ПС",
		"purse": "Кошелек",
		"amount": "Сумма",
		"status": "Статус",
		"created_at": "Дата создания",
		"status_updated_at": "Дата обновления статуса",
		"total": "Всего",
		"support_status": {
			"title": "Статус суппорта",
			"payable": "Можно оплатить",
			"not_payable": "Не платить"
		},
		"failure_types": {
			"insufficient_funds": "Недостаточный баланс",
			"processor_decline": "Ошибка процессинга",
			"user_failure": "Ошибка пользователя",
			"timeout": "Таймаут",
			"terminal": "Ошибка терминала"
		},
		"rejection_reasons": {
			"balance": "Недостаточный баланс",
			"purse": "Неверный кошелек",
			"support_request": "Запрос суппорта",
			"banned_customer": "Игрок забанен",
			"old_payout": "Просроченная выплата",
			"other": "Другое"
		},
		"bank": "Банк",
		"billing_amount": "Сумма платежа",
		"billing_currency": "Валюта платежа",
		"billing_type": "Тип платежа",
		"customer_country": "Страна игрока",
		"customer_fee": "Наша комиссия",
		"project_fee": "Комиссия проекта",
		"personal_fee": "Персональная комиссия",
		"failure_code": "Код отказа",
		"failure_type": "Тип отказа",
		"order_currency": "Валюта заявки",
		"id": "ID заявки",
		"jurisdiction": "Юрисдикция",
		"locale": "Локаль",
		"platform": "Платформа",
		"processed_at": "Дата обработки процессингом",
		"processor_channel": "Канал процессинга",
		"processor_fee": "Комиссия процессинга (расчетная)",
		"processor_fee_external": "Комиссия процессинга (внешняя)",
		"project_name": "Проект",
		"refcode": "Реферальный код",
		"rejection_reason": "Причина отклонения",
		"scheduled_at": "Выплата запланирована",
		"secondary_txids": "Вторичные ID",
		"token_country": "Страна по IP",
		"transaction_id": "ID транзакции",
		"updated_at": "Заявка обновлена",
		"user": "Пользователь",
		"send": "Отправить",
		"processor_account_title": "Аккаунт процессинга",
		"deposit_number": "Номер депозита",
		"three_ds_status": "3DS статус",
		"purse_country": "Страна кошелька",
		"banCard": {
			"customer": "Для игрока",
			"global": "Глобально",
			"type": "Тип"
		},
		"card_type": "Тип карты",
		"amount_filter_tooltip": "Для USD идет поиск по всем заявкам, сумма заявки при этом конвертируется в USD. Для других валют поиск ведется только по заявкам с указанной валютой."
	}
};