module.exports = {
	"order": {
		"mass_update": {
			"action": "Action",
			"reason": "Reason",
			"comment": "Comment",
			"left": "Left",
			"failed": "Failed"
		},
		"action": {
			"blank": "Comment",
			"payout": "Payout now",
			"payoutAtTime": "Payout at time",
			"reject": "Reject",
			"repay": "Repay",
			"refund": "Request refund",
			"declareRefund": "Declare taken refund",
			"redeposit": "Create a repeat deposit",
			"decreasePayout": "Decrease a payout amount",
			"action_success": "Action is successfully completed",
			"banCard": "Ban card",
			"unbanCard": "Unban card",
			"cancel": "Cancel in processor"
		},
		"agree": {
			"repay": "I am sure that the payment didn't go to processing",
			"refund": "I am sure that I want to refund",
			"redeposit": "I am sure that I want to re-deposit",
			"decreasePayout": "I am sure that the customer received a smaller amount than requested"
		},
		"rejectAll": "Reject all customer's orders",
		"title": "Orders",
		"order": "Order",
		"download": "Download",
		"information": {
			"title": "Information",
			"order_details": {
				"title": "Order details",
				"id": "Order ID",
				"project": "Project",
				"jurisdiction": "Jurisdiction",
				"customer_country": "Customer's country",
				"type": "Type",
				"amount": "Amount",
				"billing_amount": "Billing amount",
				"refundable_amount": "Refundable amount",
				"refunds_amount": "Refunds amount",
				"billing_type": "Billing type",
				"processor": "Processor",
				"processor_channel": "Processor channel",
				"system": "System",
				"bank": "Bank",
				"purse": "Purse",
				"processor_fee": "Processor fee (calculated)",
				"processor_fee_external": "Processor fee (external)",
				"project_fee": "Project fee",
				"customer_fee": "Customer fee",
				"personal_fee": "Personal fee",
				"external_fee": "Coin network fee",
				"status": "Status",
				"error_code": "Error code",
				"error_type": "Error type",
				"support_status": "Support status",
				"rejection_reason": "Rejection reason",
				"status_updated_at": "Status updated at",
				"transaction_id": "Transaction ID",
				"secondary_txids": "Secondary transaction IDs",
				"created_at": "Created at",
				"updated_at": "Updated at",
				"scheduled_at": "Scheduled at",
				"refcode": "Refcode",
				"processor_account": "Processor account",
				"processed_at": "Processed at",
				"three_ds_status": "3DS status",
				"deposit_number": "Deposit number",
				"purse_country": "Purse country"
			},
			"three_ds_status": {
				"passed": "3DS passed",
				"failed": "3DS failed or not attempted",
				"attempted": "3DS was attempted, yet not performed",
				"not_initiated": "Non 3DS",
				"no_data": "No data"
			},
			"processor_details": {
				"title": "Processor details"
			},
			"purse_details": {
				"title": "Purse details"
			},
			"token_details": {
				"title": "Session details",
				"guid": "GUID",
				"locale": "Locale",
				"country": "Country by IP",
				"mode": "Widget mode",
				"mobile": "Mobile",
				"device": "Device",
				"browser": "Browser",
				"browser_engine": "Engine",
				"browser_is_webview": "Webview",
				"os": "OS",
				"widget_origin": "Widget origin",
				"vwo_variations": "VWO variations",
				"tracking_id": "Tracking ID",
				"platform": "Platform",
				"source": "Source",
				"open_time": "Cashier open time"
			},
			"order_comments": {
				"title": "Order comments"
			},
			"customer_comments": {
				"title": "Customer comments"
			},
			"customer_details": {
				"title": "Customer details"
			},
			"bin": {
				"title": "BIN data",
				"brand": "Brand",
				"issuer": "Bank-issuer",
				"type": "Type",
				"level": "Level",
				"prepaid": "Prepaid",
				"business": "Business",
				"country": "Country",
				"website": "Bank website",
				"phone": "Phone",
				"add_info": "Add. info"
			}
		},
		"action_logs": {
			"title": "Action logs",
			"user": "User",
			"type": "Type",
			"action": "Action",
			"date": "Date"
		},
		"tech_logs": {
			"title": "Technical logs",
			"types": {
				"project_request": "Project requests",
				"processor_request": "Processor requests",
				"callback": "Callbacks",
				"return": "Returns",
				"widget_response": "Widget responses"
			}
		},
		"status_history": {
			"title": "Status history",
			"old_status": "Old status",
			"new_status": "New status",
			"change_interval": "Time to change status",
			"user": "User",
			"changed_at": "Status change date",
			"order_created_at": "Order creation date"
		},
		"operations": {
			"title": "Operations",
			"id": "Operation ID",
			"guid": "Operation GUID",
			"txid": "Transaction ID",
			"status": "Status",
			"createdAt": "Created at",
			"updatedAt": "Updated at",
			"amount": "Operation amount",
			"type": "Type",
			"types": {
				"deposit": "Deposit",
				"withdraw": "Withdraw",
				"refund": "Refund",
				"cancel": "Cancel"
			},
			"statusUpdatedAt": "Status updated at"
		},
		"external_operations": {
			"title": "External operations",
			"operation_id": "Operation ID",
			"id": "ID",
			"guid": "GUID",
			"type": "Type",
			"types": {
				"external_3ds": "External 3DS",
				"authorization": "Authorization",
				"payout": "Payout",
				"void": "Void",
				"capture": "Capture",
				"refund": "Refund"
			},
			"cascade_number": "Cascade Number",
			"attempt_number": "Attempt Number",
			"status": "Status",
			"amount": "Amount",
			"provider": "Provider",
			"provider_amount": "Provider Amount",
			"provider_fee": "Provider Fee",
			"provider_reference": "Provider Reference",
			"decline_info": "Decline info",
			"decline_info_fields": {
				"reason": "Reason",
				"type": "Type",
				"code": "Code",
				"message": "Message"
			},
			"created_at": "Created At",
			"status_updated_at": "Status Updated At"
		},
		"billing_types": {
			"normal": "Normal",
			"free": "Free",
			"test": "Test",
			"internal": "Internal"
		},
		"player": "Player",
		"type": "Type",
		"processor": "Processor",
		"payment_system": "PS",
		"purse": "Purse",
		"amount": "Amount",
		"status": "Status",
		"created_at": "Created at",
		"status_updated_at": "Status updated at",
		"total": "Total",
		"support_status": {
			"title": "Support status",
			"payable": "Payable",
			"not_payable": "Not payable"
		},
		"failure_types": {
			"insufficient_funds": "Insufficient funds",
			"processor_decline": "Processor decline",
			"user_failure": "User failure",
			"timeout": "Timeout",
			"terminal": "Terminal failure"
		},
		"rejection_reasons": {
			"balance": "Insufficient balance",
			"purse": "Wrong purse",
			"support_request": "Support request",
			"banned_customer": "Banned customer",
			"old_payout": "Expired payout",
			"other": "Other"
		},
		"bank": "Bank",
		"billing_amount": "Billing amount",
		"billing_currency": "Billing currency",
		"billing_type": "Billing type",
		"customer_country": "Customer's country",
		"customer_fee": "Customer fee",
		"project_fee": "Project fee",
		"personal_fee": "Personal fee",
		"failure_code": "Failure code",
		"failure_type": "Failure type",
		"order_currency": "Order currency",
		"id": "Order ID",
		"jurisdiction": "Jurisdiction",
		"locale": "Locale",
		"platform": "Platform",
		"processed_at": "Processed at",
		"processor_channel": "Processor channel",
		"processor_fee": "Processor fee (calculated)",
		"processor_fee_external": "Processor fee (external)",
		"project_name": "Project",
		"refcode": "Refcode",
		"rejection_reason": "Rejection reason",
		"scheduled_at": "Scheduled at",
		"secondary_txids": "Secondary transaction IDs",
		"token_country": "Country by IP",
		"transaction_id": "Transaction ID",
		"updated_at": "Updated at",
		"user": "User",
		"send": "Send",
		"processor_account_title": "Processor account",
		"deposit_number": "Deposit number",
		"three_ds_status": "3DS status",
		"purse_country": "Purse country",
		"banCard": {
			"customer": "Customer",
			"global": "Global",
			"type": "Type"
		},
		"card_type": "Card type",
		"amount_filter_tooltip": "For USD, the order amount is converted to USD. For other currencies, only orders in the specified currency are selected."
	}
};